import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Logo from "../../images/logo.png";
import JobDetails from "./JobDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlljobs, jobsSelector } from "../../api/jobs";

import { useNavigate, useParams } from "react-router";
import moment from "moment";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import {
  fetchIndividualProfile,
  individualUserSelector,
} from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import { LuFileSearch2 } from "react-icons/lu";
import { MdAssignmentAdd } from "react-icons/md";
import { CiSaveDown2 } from "react-icons/ci";
import { HiClipboardDocumentCheck } from "react-icons/hi2";


function AppliableJobs() {
  const dispatch = useDispatch();
  const { jobs, loading } = useSelector(jobsSelector);
  const nav = useNavigate();
  const { id } = useParams();
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );

  useEffect(() => {
    dispatch(fetchAlljobs());
  }, []);

  useEffect(() => {
    dispatch(fetchIndividualProfile(individualtoken));
  }, []);

  return (
    <div style={{ fontFamily: "Lato" }}>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/jobs">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <LuFileSearch2 />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Find Jobs
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/jobapplied">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdAssignmentAdd />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Applied
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/jobsave">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <CiSaveDown2 />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Saved
                      </h4>
                    </div>
                  </div>
                </NavLink>
                {/* <NavLink to="/JobShortlisted">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <HiClipboardDocumentCheck />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Shortlisted
                      </h4>
                    </div>
                  </div>
                </NavLink> */}
              </div>
            </div>

            <div class="w-full lg:w-[78%] px-4 pt-2">
              <div class="h-full  py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                {/* <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}> */}

                <JobDetails id={id} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AppliableJobs;
