import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, NavLink, Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { individualUserSelector } from "../../api/individualUser";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkIcon from "@mui/icons-material/Work";
import { RWebShare } from "react-web-share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import nodata from "../../images/no-data.png";
import {
  fetchAllPublish,
  publishSelector,
  getAllPublishSuccessful,
  savePublish,
  unSavePublish,
} from "../../api/publish";
import { keyUri } from "../../key";
// import { GrCertificate } from "react-icons/gr";
import { PiCertificateDuotone } from "react-icons/pi";
import { IoDocument } from "react-icons/io5";
import { MdAssignmentTurnedIn, MdPublish } from "react-icons/md";
import { GiArchiveResearch, GiSaveArrow } from "react-icons/gi";
import { IoMdDownload } from "react-icons/io";
// import { MdPublish } from "react-icons/md";
// import { BsSave2Fill } from "react-icons/bs";


const useStyles = makeStyles(() => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function TopicsDisplays() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { individualtoken, user } = useSelector(individualUserSelector);
  const { publish, currentPublish } = useSelector(publishSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [userPosts, setUserPosts] = useState([]); // Posts by individuals
  const [ioiPosts, setIoiPosts] = useState([]); // Posts by IOIs

  useEffect(() => {
    dispatch(fetchAllPublish());
  }, [dispatch]);

  // WebSocket connection to get updates
  useEffect(() => {
    const ws = new WebSocket(keyUri.SOCKET_URI);
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "PUBLISH_CREATED") {
        dispatch(getAllPublishSuccessful({ currentPublish }));
      }
    };
    return () => ws.close();
  }, [currentPublish, dispatch]);

  useEffect(() => {
    // Filter and separate posts by user and IOI
    const userPosts = publish.filter((item) => item.publish_posted_by_user);
    const ioiPosts = publish.filter((item) => item.publish_posted_by_ioi);

    setUserPosts(userPosts);
    setIoiPosts(ioiPosts);
  }, [publish]);

  const handleSavePublish = (publishId) => {
    if (user?.publish_saved?.some((saved) => saved._id === publishId)) {
      dispatch(unSavePublish(user._id, publishId, individualtoken));
    } else {
      dispatch(savePublish(user._id, publishId, individualtoken));
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  // Filtered posts based on the search query
  const filteredUserPosts = userPosts.filter((post) =>
    post.publish_posted_by_user?.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const filteredIoiPosts = ioiPosts.filter((post) =>
    post.publish_posted_by_ioi?.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            {/* Left Sidebar */}
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer ${
                      location.pathname.includes("publish")
                        ? "bg-[#CEDDF6]"
                        : "bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PiCertificateDuotone className="text-white" />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Documents">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                     `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoDocument />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Projects">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                   
                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdAssignmentTurnedIn />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Projects
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Researchpapers">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer
                   
                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <GiArchiveResearch />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyUploads">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdPublish />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/saved-publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoMdDownload />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* Main Content */}
            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className=" flex flex-col lg:flex-row justify-between items-center lg:items-end">
                    <div className="items-center text-center pb-2">
                      {/* Heading */}
                      <h2 className="text-xl font-semibold text-[#0F3D79] ">
                        Publish - Patent Certificates
                      </h2>
                    </div>

                    {/* Search, Filter, and Create Buttons */}
                    <div className="flex flex-col lg:flex-row justify-end items-center w-full lg:w-auto">
                      {/* Search Bar */}
                      <div className="relative w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search Individual / Company Name"
                          className="py-2 pl-10 pr-4 w-full lg:w-[300px] rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>

                      {/* Filter Button */}
                      <div className="w-full lg:w-auto mb-4 lg:mb-0 lg:mr-4">
                        <button
                          className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full lg:w-auto"
                          type="button"
                        >
                          <FilterIcon /> Filter
                        </button>
                      </div>

                      {/* Create Button */}
                      <div className="w-full lg:w-auto">
                        <Link
                          to="/PublishCertificate"
                          className="w-full lg:w-auto"
                        >
                          <button
                            className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full lg:w-auto"
                            type="button"
                            onClick={reloadButton}
                          >
                            <AddIcon /> Create
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* Display Topics */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* User Posts */}
                    <div className="col-span-full mt-6">
                      <h3 className="text-xl font-semibold text-[#0F3D79]">
                        Organisation
                      </h3>
                    </div>
                    {filteredIoiPosts.length > 0 ? (
                      filteredIoiPosts.map((item, index) => (
                        <div
                          key={item._id}
                          className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]"
                        >
                          <NavLink to={`/PublishView/${item._id}`}>
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.publish_posted_by_ioi
                                    ?.profile_completion?.logo ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.publish_posted_by_ioi?.name || "Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.publish_posted_by_ioi?.sub_category ||
                                    "Category"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.skills[0] || "Domain"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item?.description.substring(0, 100)}...
                              <NavLink
                                to={`/ViewCertificate/${item._id}`}
                                className="text-blue-600"
                              >
                                Read more
                              </NavLink>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </NavLink>

                          {/* Save and Share Buttons */}
                          <div className="flex items-center mt-4 gap-x-4">
                            <RWebShare
                              data={{
                                text: "Check out this document",
                                url: `https://ioi.bharc.in/ViewCertificate/${item._id}`,
                                title: "Document Share",
                              }}
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>
                            {user?.publish_saved?.some(
                              (saved) => saved._id === item._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "white" }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "#346BB7" }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-span-full flex flex-col justify-center items-center"></div>
                    )}
                    <div className="col-span-full">
                      <h3 className="text-xl font-semibold text-[#0F3D79]">
                        Individual
                      </h3>
                    </div>
                    {filteredUserPosts.length > 0 ? (
                      filteredUserPosts.map((item, index) => (
                        <div
                          key={item._id}
                          className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]"
                        >
                          <NavLink to={`/PublishView/${item._id}`}>
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.publish_posted_by_user
                                    ?.profile_completion?.profile_pic ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.publish_posted_by_user?.name || "Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.publish_posted_by_user
                                    ?.profile_completion?.designation ||
                                    "Designation"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.skills[0] || "Domain"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item?.description.substring(0, 100)}...
                              <NavLink
                                to={`/ViewCertificate/${item._id}`}
                                className="text-blue-600"
                              >
                                Read more
                              </NavLink>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </NavLink>

                          {/* Save and Share Buttons */}
                          <div className="flex items-center mt-4 gap-x-4">
                            <RWebShare
                              data={{
                                text: "Check out this document",
                                url: `https://user.bharc.in/ViewCertificate/${item._id}`,
                                title: "Document Share",
                              }}
                            >
                              <button className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold">
                                Share
                                <ShareIcon className="ml-2 text-[#346BB7]" />
                              </button>
                            </RWebShare>
                            {user?.publish_saved?.some(
                              (saved) => saved._id === item._id
                            ) ? (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-[#002357] border border-gray-300 rounded-lg text-sm text-white hover:bg-[#001a44] font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Saved
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "white" }}
                                />
                              </button>
                            ) : (
                              <button
                                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-600 hover:bg-gray-100 font-semibold"
                                onClick={() => handleSavePublish(item._id)}
                              >
                                Save
                                <BookmarkBorderOutlinedIcon
                                  className="ml-2"
                                  style={{ fill: "#346BB7" }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-span-full flex flex-col justify-center items-center"></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopicsDisplays;
