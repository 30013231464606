import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Image1 from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
// import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import pdfff from "../../images/pdff.pdf";
import { publishSelector } from "../../api/publish";
import { fetchOnePublish, deletePublish } from "../../api/publish";
import { useParams } from "react-router";
import { FaMinus } from "react-icons/fa";
import { PiCertificateDuotone } from "react-icons/pi";
import { IoDocument } from "react-icons/io5";
import { MdAssignmentTurnedIn, MdPublish } from "react-icons/md";
import { GiArchiveResearch, GiSaveArrow } from "react-icons/gi";
import { IoMdDownload } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function PublishView({ route }) {
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { publish, currentPublish } = useSelector(publishSelector);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchOnePublish(id));
  }, [id]);

  const handleDeletePublish = (deleteId) => {
    dispatch(deletePublish(deleteId));
    navigate("/publish");
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  // Filtered topics based on search query

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            {/* <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              {route ? <ProfileLogoInd user={user} /> : ""}
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>

                <NavLink to="/MyUploads">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div> */}

            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              {route ? <ProfileLogoInd user={user} /> : ""}
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer ${
                      location.pathname.includes("publish")
                        ? "bg-[#CEDDF6]"
                        : "bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PiCertificateDuotone className="text-white" />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Documents">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                     `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoDocument />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Projects">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                   
                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdAssignmentTurnedIn />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Projects
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Researchpapers">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer
                   
                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <GiArchiveResearch />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyUploads">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdPublish />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/saved-publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoMdDownload />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  {/* <div className="flex justify-between sm:flex-row items-center w-full lg:w-auto">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Publish
                      <span className="text-sm"> - Patent Certificates</span>
                    </h2>
                    <div className="flex justify-between">
                      {user?._id ===
                      currentPublish?.publish_posted_by_user?._id ? (
                        <button
                          className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                          type="button"
                          onClick={() => handleDeletePublish(id)}
                        >
                          <FaMinus /> Delete
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div> */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    <div className="m-4 d-flex">
                      <h2 className="text-lg font-semibold text-[#0F3D79]">
                        Publish
                        <span className="text-sm"> - Patent Certificates</span>
                      </h2>
                    </div>

                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      {user?._id ===
                      currentPublish?.publish_posted_by_user?._id ? (
                        <button
                          className="py-2 px-6 flex items-center text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                          type="button"
                          onClick={() => handleDeletePublish(id)}
                        >
                          <FaMinus className="mr-2" />{" "}
                          {/* Add margin-right for spacing */}
                          Delete
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {currentPublish?.publish_posted_by_user ? (
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
                      <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0 h-[500px] overflow-auto">
                        <div className="border p-4 rounded-md h-[500px]">
                          <div className="flex items-center mb-4">
                            <img
                              src={
                                currentPublish?.publish_posted_by_user
                                  ?.profile_completion?.profile_pic
                                  ? currentPublish?.publish_posted_by_user
                                      ?.profile_completion?.profile_pic
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                              }
                              alt="Profile"
                              className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                            />
                            <div>
                              <h3 className="text-[#0F3D79] text-md font-semibold">
                                {currentPublish?.publish_posted_by_user?.name}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {currentPublish?.publish_posted_by_user
                                  ?.profile_completion?.designation || ""}
                              </p>
                            </div>
                          </div>

                          <h4 className="mt-4 text-[#0F3D79] text-md font-semibold">
                            {currentPublish?.certificate_name || ""}
                          </h4>
                          <p className="mt-2 text-gray-700">
                            {currentPublish?.description || ""}
                          </p>
                        </div>
                      </div>

                      {/* PDF Display */}
                      <div className="w-full lg:w-1/2 flex justify-center ml-2">
                        <iframe
                          src={
                            currentPublish?.document
                              ? currentPublish?.document
                              : pdfff
                          } // Replace with your PDF URL
                          width="100%"
                          height="500px" // Adjust the height as needed
                          style={{ border: "none", backgroundColor: "white" }}
                          title="PDF Viewer"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center ">
                      <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0 h-[500px] overflow-auto">
                        <div className="border p-4 rounded-md h-full">
                          <div className="flex items-center mb-4">
                            <img
                              src={
                                currentPublish?.publish_posted_by_ioi
                                  ?.profile_completion.logo
                                  ? currentPublish?.publish_posted_by_ioi
                                      ?.profile_completion.logo
                                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                              }
                              alt="Profile"
                              className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                            />
                            <div>
                              <h3 className="text-[#0F3D79] text-md font-semibold">
                                {currentPublish?.publish_posted_by_ioi?.name}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {currentPublish?.publish_posted_by_ioi
                                  ?.sub_category || ""}
                              </p>
                            </div>
                          </div>

                          <h4 className="mt-4 text-[#0F3D79] text-md font-semibold">
                            {currentPublish?.certificate_name || ""}
                          </h4>
                          <p className="mt-2 text-gray-700">
                            {currentPublish?.description || ""}
                          </p>
                        </div>
                      </div>

                      {/* PDF Display */}
                      <div className="w-full lg:w-1/2 flex justify-center ml-2">
                        <iframe
                          src={
                            currentPublish?.document
                              ? currentPublish?.document
                              : pdfff
                          } // Replace with your PDF URL
                          width="100%"
                          height="500px" // Adjust the height as needed
                          style={{ border: "none", backgroundColor: "white" }}
                          title="PDF Viewer"
                        />
                      </div>
                    </div>
                  )}

                  {/* Content with image */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PublishView;
