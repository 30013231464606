import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

export const initialState = {
  loading: false,
  hasErrors: false,
  jobs: [],
  Ioiuser_job: [],
  ioiJobs: [],
  intrestedJobs: [],
  followedJobs: [],
  currentjob: {},
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    getJobLoading: (state) => {
      state.loading = true;
    },
    getAllJobsSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.jobs = payload?.all_users;
    },
    getJobsOfIoi: (state, { payload }) => {
      state.loading = false;
      state.ioiJobs = payload;
    },
    getCrrentJobSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentjob = payload?.job;
    },
    getIoiUserJobs: (state, { payload }) => {
      state.loading = false;
      state.Ioiuser_job = payload?.jobApplicants;
    },
    getIntrestedJobs: (state, { payload }) => {
      state.loading = false;
      state.intrestedJobs = payload?.jobsfetched;
    },
    getFollowingJobs: (state, { payload }) => {
      state.loading = false;
      state.followedJobs = payload?.jobsfetched;
    },
    getJobFalied: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getJobLoading,
  getAllJobsSuccessfull,
  getJobsOfIoi,
  getCrrentJobSuccessfull,
  getIoiUserJobs,
  getIntrestedJobs,
  getFollowingJobs,
  getJobFalied,
} = jobsSlice.actions;

export const jobsSelector = (state) => state.jobs;
export default jobsSlice.reducer;

export const fetchAlljobs = (id) => async (dispatch) => {
  const key = "jobs";
  dispatch(getJobLoading());
  try {
    const { data } = await axios(keyUri.BACKEND_URI + "/ioiUsers/get-all-job");
    // //console.log(data, "this is data")
    dispatch(getAllJobsSuccessfull(data));
    // toast.success(`${data.message}`, {
    //     position: "top-center",
    //     autoClose: 1000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });

  } catch (error) {
    // toast.success(`${error.response.data.message}`, {
    //   position: "top-center",
    //   autoClose: 1000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
    dispatch(getJobFalied());
  }
};

export const createJobs = (value) => async (dispatch) => {
  const key = "job";
  dispatch(getJobLoading());
  try {
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/ioiUsers/post-job",
      value,
      config
    );
    dispatch(getAllJobsSuccessfull());
    toast.success(`${data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.success(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const fetchOneJob = (id) => async (dispatch) => {
  const key = "job";
  dispatch(getJobLoading());
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/ioiUsers/get-a-job/${id}`
      );
      dispatch(getCrrentJobSuccessfull(data));
    }
  } catch (error) {
    toast.success(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const  jobsApply = (data,id) => async (dispatch) => {
  try {
    const response = await axios.put(
      keyUri.BACKEND_URI + `/jobApplications/${id}`,
      data,
      config
    );
    toast.success(`${response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(fetchAlljobs());
    dispatch(fetchOneJob(data.job_id));
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const fetchPostedJobs = (id) => async (dispatch) => {
  dispatch(getJobLoading());
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/ioiUsers/job-applicants/${id}`
      );
      dispatch(getIoiUserJobs(data));
    }
  } catch (error) {
    toast.error(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getJobFalied());
  }
};

export const fetchJobsBySkills = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/fetch-domain-based-job-individual/${id}`
      );
      // //console.log(data);
      dispatch(getIntrestedJobs(data));
    }
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};

export const fetchJobsByFollowing = (id) => async (dispatch) => {
  if (id) {
    try {
      // //console.log(id)
      const { data } = await axios(
        keyUri.BACKEND_URI + `/users/fetch-follow-based-job-individual/${id}`
      );
      // //console.log(data);
      dispatch(getFollowingJobs(data));
    } catch (error) {
      // console.warn(error);
      // // toast.error(`${error?.response?.data?.message}`, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }
};
