import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import { RWebShare } from "react-web-share";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { savePublish, unSavePublish, publishSelector } from "../../api/publish";
import { PiCertificateDuotone } from "react-icons/pi";
import { IoDocument } from "react-icons/io5";
import { MdAssignmentTurnedIn, MdPublish } from "react-icons/md";
import { GiArchiveResearch, GiSaveArrow } from "react-icons/gi";
import { IoMdDownload } from "react-icons/io";
import { CircularProgress } from "@mui/material"; // Import CircularProgress
import { FaHammer } from "react-icons/fa"; // Construction icon from react-icons

function Projects() {
  const { individualtoken, user } = useSelector(individualUserSelector);
  const { publish, currentPublish } = useSelector(publishSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Add loading state

  // Simulate fetching data or waiting for a response
  useEffect(() => {
    setTimeout(() => {
      setLoading(true); // Set loading to false after content is ready
    }, 2000); // 2-second delay to simulate loading
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  const trimText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const trimmedText = text.substr(0, maxLength);
    return (
      trimmedText.substr(
        0,
        Math.min(trimmedText.length, trimmedText.lastIndexOf(" "))
      ) + "..."
    );
  };

  const handleSaveTopic = (publishId) => {
    if (user?.publish_saved?.some((saved) => saved._id === publishId)) {
      dispatch(unSavePublish(user?._id, publishId, individualtoken));
    } else {
      dispatch(savePublish(user?._id, publishId, individualtoken));
    }
  };

  const filteredPublish = publish?.filter((pub) => {
    const postedByName = pub?.publish_posted_by_user?.name?.toLowerCase();
    const query = searchQuery?.toLowerCase();

    const matchesSearchQuery = postedByName?.includes(query);

    return matchesSearchQuery;
  });

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <PiCertificateDuotone className="text-white" />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Documents">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer`}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoDocument />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Projects">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer 
                                   
                                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdAssignmentTurnedIn />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Projects
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Researchpapers">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer
                                   
                                    `}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <GiArchiveResearch />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyUploads">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("MyUploads")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <MdPublish />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        My Published Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/saved-publish">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <IoMdDownload />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-[#0F3D79] mb-1">
                        Saved Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-4 pt-2">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center lg:items-end">
                    <div className="flex-grow flex items-center -mt-1">
                      <h2 className="text-xl font-semibold text-[#0F3D79] lg:mr-6 mb-4 lg:mb-3">
                        {/* General Documents */}
                      </h2>
                    </div>
                  </div>

                  {/* Show loading spinner or construction message */}
                  {loading ? (
                    <div className="flex justify-center items-center flex-col">
                      <p className="mt-4 text-xl text-gray-700">
                        Adding Projects is In Progress. Please check back later.
                      </p>
                      <br></br>
                      <CircularProgress />
                      {/* <FaHammer className="mt-4 text-4xl text-yellow-600 animate-spin" /> */}
                    </div>
                  ) : (
                    <div className="content">
                      {/* Render filteredPublish or any other content */}
                      <div>
                        {filteredPublish && filteredPublish.length > 0 ? (
                          filteredPublish.map((item, index) => (
                            <div key={index}>
                              <h3>{item.title}</h3>
                              <p>{trimText(item.description, 100)}</p>
                            </div>
                          ))
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
