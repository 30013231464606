import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, TextField } from "@material-ui/core";
import WorkIcon from "@mui/icons-material/Work";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneJob, jobsApply, jobsSelector } from "../../api/jobs";
import { makeStyles } from "@mui/styles";
import { Interweave } from "interweave";
import {
  fetchIndividualProfile,
  individualUserSelector,
  saveJob,
} from "../../api/individualUser";
import moment from "moment/moment";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function JobDetails({ id }) {
  const dispatch = useDispatch();
  const { currentjob, loading } = useSelector(jobsSelector);
  const { user, individualtoken } = useSelector(individualUserSelector);
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isApplied, setIsApplied] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true); // Track if apply button is visible

  // Check if the job has been applied already from localStorage
  useEffect(() => {
    dispatch(fetchOneJob(id));
    dispatch(fetchIndividualProfile(individualtoken));

    // Check localStorage if the job is already applied
    const appliedJobs = user?.appliedJobs || [];
    if (appliedJobs.includes(currentjob?._id)) {
      setIsApplied(true);
      setIsButtonVisible(false); // Hide button if job is applied
    }
  }, [id, currentjob?._id, individualtoken]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      if (uploadedFile.type !== "application/pdf") {
        toast.error("Only PDF files are allowed!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
        });
        return;
      }

      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    }
  };

  const handleApplyClick = () => {
    if (!file) {
      toast.warning("Please upload your resume or document before applying.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    // FormData to handle file submission
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", user._id);

    // Dispatch apply action with file data
    dispatch(jobsApply(formData, currentjob._id)).then(() => {
      setIsApplied(true); // Change the button to "Applied"
      setIsButtonVisible(false); // Hide the Apply button after submission
      toast.success("You have successfully applied!", {
        position: "top-center",
        autoClose: 2000,
      });

      // Save the applied job in localStorage to persist across page reloads
      const appliedJobs = JSON.parse(localStorage.getItem("appliedJobs")) || [];
      appliedJobs.push(currentjob._id);
      localStorage.setItem("appliedJobs", JSON.stringify(appliedJobs));

      setModalOpen(false);
    });
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Box>
        <h3 className="mb-2 text-xl font-bold font-heading text-[#0F3D79]">
          {currentjob?.designation}
        </h3>
        <p>
          {currentjob?.job_posted_by?.name} &nbsp;{" "}
          {moment(currentjob?.createdAt).fromNow()}
        </p>
        <br />
        <p>
          <WorkIcon /> &nbsp; {currentjob?.job_type} &nbsp; &nbsp;
          {currentjob?.mode_of_work} &nbsp;
        </p>
        <p>
          <LightbulbIcon /> &nbsp;
          {currentjob?.applicants?.length === 1
            ? `${currentjob?.applicants?.length} applicant for this job`
            : `${currentjob?.applicants?.length} applicants for this job`}
        </p>
        <br />
        <Box>
          {isButtonVisible && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "40px" }}
              onClick={handleOpenModal}
            >
              Apply
            </Button>
          )}

          {user?.jobs_saved?.find((item) => item?._id === currentjob?._id) ? (
            <Button variant="contained" color="primary">
              Saved
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                dispatch(
                  saveJob(user?._id, { id: currentjob?._id }, individualtoken)
                );
              }}
            >
              Save
            </Button>
          )}
        </Box>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            className="modal-content"
            style={{
              padding: 24,
              background: "white",
              maxWidth: 450,
              margin: "auto",
              marginTop: "15vh",
              maxHeight: "70vh",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              id="modal-title"
              gutterBottom
              style={{ fontWeight: 600, color: "#333" }}
            >
              Upload Your Resume to Apply
            </Typography>
            <TextField
              label="Select your file"
              type="file"
              fullWidth
              onChange={handleFileChange}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                disableUnderline: true,
              }}
              style={{ marginBottom: 16 }}
            />
            {fileName && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                {fileName}
              </Typography>
            )}

            <Box
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                color="secondary"
                style={{
                  width: "48%",
                  padding: "8px 16px",
                }}
              >
                Cancel
              </Button>

              {file ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "40px" }}
                  onClick={handleApplyClick}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "40px" }}
                  onClick={handleApplyClick}
                  disabled
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Modal>

        <h3 className="mt-5 text-xl font-bold font-heading text-[#0F3D79]">
          About job
        </h3>

        <div className="mt-5 text-gray-800">
          <h4 className="mt-5 font-bold font-heading text-[#0F3D79]">
            Job Description
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob?.job_description}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
          <h4 className="mt-5 font-bold font-heading text-[#0F3D79]">
            Job Responsibility
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob?.job_requirement}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
          <h4 className="mt-5 font-bold font-heading text-[#0F3D79]">
            Job Requirements
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob?.job_responsibility}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}

export default JobDetails;
